import React from 'react'
import Spinner from 'react-svg-spinner'
import JSZip from 'jszip'
import { getEntitiesToExport } from 'components/payments/PaymentsDataCorrelation'

import * as PaymentsApi from 'services/api/payments/paymentApi'
import { useAlert } from 'hooks/useAlert'
import dateService from 'services/date'
import { getColor } from 'utils/styles'
import { dataToCSV, csvToBlob } from 'utils/csv'
import saveBlob from 'utils/saveBlob'

import Card from 'components/platform/Card'
import BackOfficeLayout from 'layouts/back-office'
import DownloadIcon from 'components/svg/Download'
import { array } from 'prop-types'

const SCOPING_MEETING_PROVIDER_REFUND_ACRONYM = 'RCA'
const SCOPING_MEETING_PROVIDER_REFUND_GENDER = '0'

const AUDIT_PROVIDER_REFUND_ACRONYM = 'AUD'
const AUDIT_PROVIDER_REFUND_GENDER = '1'

const BENEFICIARY_REFUND_ACRONYM = 'BENEF'
const BENEFICIARY_REFUND_GENDER = '2'
const BENEFICIARY_REGULARISATION_GENDER = '3'


const paymentsStateReducer = (paymentsState, action) => {
    const { type, key, data = [] } = action
    switch (type) {
        case 'LOADING': {
            return {
                ...paymentsState,
                [key]: {
                    data: [],
                    loading: true
                }
            }
        }
        case 'SUCCESS': {
            return {
                ...paymentsState,
                [key]: {
                    data,
                    loading: false
                }
            }
        }
        case 'INIT-HISTORY': {
            return {
                ...paymentsState,
                ...(data.reduce((acc, curr) => {
                    return {
                        ...acc,
                        [`RefundInfos-${curr.key}`]: {
                            data: [],
                            loading: false
                        },
                        [`RefundDetails-${curr.key}`]: {
                            data: [],
                            loading: false
                        },
                    }
                }, {}))
            }
        }
        case 'ERROR': {
            return {
                ...paymentsState,
                [key]: {
                    data: [],
                    loading: false
                }
            }
        }
        default:
            return paymentsState
    }
}

const defaultLinksEnum = {
    tiers: {
        data: [],
        loading: false
    },
    tiersRIB: {
        data: [],
        loading: false
    }
    // Note that in the state will be dynamically added:
    // - an object for each current refundInfos of each gender : `RefundInfos-${refundGender}`
    // - an object for each current refundDetails of each gender : `RefundDetails-${refundGender}`
    // - an object for each current refundInfos of each refund of the history : `RefundInfos-${refundId}`
    // - an object for each current refundDetails of each refund of the history : `RefundDetails-${refundId}`
}

const Payments = () => {
    const { addAlert } = useAlert()
    const currentDate = dateService.getCurrentDate('dd-MM-y')

    const [paymentsState, dispatchPayments] = React.useReducer(paymentsStateReducer, defaultLinksEnum)
    const [refundsHistory, setRefundsHistory] = React.useState([])

    // Refund history management
    // ------------------------------
    const getRefundsHistory = async () => {
        try {
            const { res: history, getError } = await PaymentsApi.getRefunds()

            if (getError()) throw getError()
            setRefundsHistory(history)

            // Init state for each refund and each refund gender
            const historyInitStateData = history
                .map(({ refund_id }) => ({ key: refund_id }))
                .concat(
                    [SCOPING_MEETING_PROVIDER_REFUND_ACRONYM, AUDIT_PROVIDER_REFUND_ACRONYM]
                    .map((acronym) => ({ key: acronym }))
                )
            dispatchPayments({ type: 'INIT-HISTORY', data: historyInitStateData })

        } catch(error) {
            console.error(error)
            addAlert('error', error?.toString())
        }
    }

    React.useEffect(() => {
        // get history on load
        getRefundsHistory()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Tiers and RIB
    // ------------------------------
    const downloadCSVTiersAndRIB = async () => {
        try {
            dispatchPayments({ type: 'LOADING', key: 'tiers' })
            const { res: tiers, getError } = await PaymentsApi.getTiers()
            if (getError()) throw getError()
            // tiers is an object with an entry for each tiersType
            // Our dataToCSV function is expecting an array of arrays
            const flatTiers = Object.values(tiers).reduce((acc, curr) => [...acc, ...curr], [])

            // Tiers
            const tiersData = getEntitiesToExport(flatTiers, 'tiers')
            dispatchPayments({ type: 'SUCCESS', key: 'tiers', data: tiersData })
            const tiersCSV = dataToCSV(tiersData)
            const tiersBlob = csvToBlob(tiersCSV)

            // Tiers RIB
            const tiersRIBData = getEntitiesToExport(flatTiers, 'tiersRIB')
            dispatchPayments({ type: 'SUCCESS', key: 'tiersRIB', data: tiersRIBData })
            const tiersRIBCSV = dataToCSV(tiersRIBData)
            const tiersRIBBlob = csvToBlob(tiersRIBCSV)

            // Generate ZIP
            const tiersZIP = new JSZip()
            tiersZIP.file(`export-tiers-${currentDate}.csv`, tiersBlob)
            tiersZIP.file(`export-tiers-RIB-${currentDate}.csv`, tiersRIBBlob)
            await tiersZIP.generateAsync({
                type: 'blob',
                compression: 'STORE', // no compression
            }).then((blob) => saveBlob(blob, `export-tiers-${currentDate}`))

        } catch(error) {
            console.error(error)
            dispatchPayments({ type: 'ERROR', key: 'tiers' })
            dispatchPayments({ type: 'ERROR', key: 'tiersRIB' })
            addAlert('error', error?.toString())
        }
    }

    // Refunds Infos and Details
    // ------------------------------
    const downloadCSVRefundInfosAndDetails = async ({ refundGender, refundId }) => {
        // Note if a refundId is provided, the data is generated from the history
        // else the data is provided from the current requests to refund
        const refundInfosKey = refundId ? `Refund-${refundId}` : `RefundInfos-${refundGender}`
        const refundDetailsKey = refundId ? `Refund-${refundId}` : `RefundDetails-${refundGender}`

        const isBeneficiary = refundGender === BENEFICIARY_REFUND_ACRONYM
        try {
            dispatchPayments({ type: 'LOADING', key: refundInfosKey })

            if (!refundId) {
                // Trigger the refund group for the month
                const { res: refundGroup, getError: getPutRefundError } = await PaymentsApi.putRefund(refundGender)
                if (getPutRefundError() || !refundGroup.refund_id) throw getPutRefundError()
                refundId = refundGroup.refund_id
            }

            // Get the refunded group details
            const { res: refundGroupDetails, getError: getRefundError } = await PaymentsApi.getRefundById(refundId)
            if (getRefundError()) throw getRefundError()

            // Refunds infos ("entête")
            const refundInfosData = getEntitiesToExport(refundGroupDetails, 'refundInfos')
            dispatchPayments({ type: 'SUCCESS', key: refundInfosKey, data: refundInfosData })
            const refundInfosCSV = dataToCSV(refundInfosData)
            const refundInfosBlob = csvToBlob(refundInfosCSV)

            // Refunds details ("ligne de facture")
            const refundGroupDetailsRounded = refundGroupDetails.map((r) => { return {...r, request_amount_to_refund: Math.round(r?.request_amount_to_refund)} } )
            const refundDetailsData = getEntitiesToExport(refundGroupDetailsRounded, isBeneficiary ? 'refundDetailsBeneficiaries' : 'refundDetails')
            dispatchPayments({ type: 'SUCCESS', key: refundDetailsKey, data: refundDetailsData })
            const refundDetailsCSV = dataToCSV(refundDetailsData)
            const refundDetailsBlob = csvToBlob(refundDetailsCSV)

            // Generate ZIP
            const tiersZIP = new JSZip()
            const explicitRefundGenderNamed = {
                [AUDIT_PROVIDER_REFUND_ACRONYM]: 'auditeurs',
                [SCOPING_MEETING_PROVIDER_REFUND_ACRONYM]: 'intervenant-reunion-cadrage',
                [BENEFICIARY_REFUND_ACRONYM]: 'beneficiaires',
            }
            tiersZIP.file(`export-entetes-paiements-${explicitRefundGenderNamed[refundGender]}-${currentDate}.csv`, refundInfosBlob)
            tiersZIP.file(`export-lignes-paiements-${explicitRefundGenderNamed[refundGender]}-${currentDate}.csv`, refundDetailsBlob)
            await tiersZIP.generateAsync({
                type: 'blob',
                compression: 'STORE', // no compression
            }).then((blob) => {
                saveBlob(blob, `export-${explicitRefundGenderNamed[refundGender]}-${currentDate}`)
                getRefundsHistory()
            })

        } catch(error) {
            console.error(error)
            dispatchPayments({ type: 'ERROR', key: refundInfosKey })
            dispatchPayments({ type: 'ERROR', key: refundDetailsKey })
            addAlert('error', error?.toString())
        }
    }

    // Inner comps
    // ------------------
    const DownloadLink = ({ label = '', onClick = null, loadingStateKeysToWatch = null }) => {
        const isLoading = loadingStateKeysToWatch
            // the button is loading if at least one of the loadingStateKeysToWatch is loading
            ? loadingStateKeysToWatch.map(stateKey => paymentsState[stateKey]?.loading).some(loadingState => loadingState)
            : false
        return (
            <button
                className="u-flex u-flex-center-vt u-mg-left-m"
                type="button"
                onClick={onClick}
                disabled={isLoading}
                aria-label={isLoading ? 'Chargement' : ''}
            >
                {isLoading && <Spinner size="16px" />}
                {!isLoading && (
                    <>
                        <DownloadIcon className="vertical-align" color={getColor().primary} />
                        <span className="u-mg-left-s">{label}</span>
                    </>
                )}
            </button>
        )
    }

    const RefundsHistoryTable = ({ refunds, refundGender }) => {
        return (
            <table className="c-table">
                <tbody>
                    {refunds.map((refund) => {
                        const linkLabel = `N°${refund.refund_id} (${Math.round(refund.amount_refunded)}€) - le ${dateService.formatDate(refund.create_date, 'dd/MM/yyyy')} par ${refund.fub_user_name}`
                        return (
                            <tr className={'c-table__row u-pd-s u-pd-0@main'} key={`${refund.refund_id}-${refund.amount_refunded}`}>
                                <td className='c-table__cell u-pd-s@main'>
                                    <DownloadLink
                                        label={linkLabel}
                                        onClick={() => downloadCSVRefundInfosAndDetails({ refundGender, refundId: refund.refund_id })}
                                        loadingStateKeysToWatch={[`RefundInfos-${refund.refund_id}`, `RefundDetails-${refund.refund_id}`]}
                                    />
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        )
    }

    RefundsHistoryTable.propTypes = {
        refunds: array
    }

    return (
        <BackOfficeLayout>
            <div className="u-flex u-flex-dir-col">
                <h1 className="c-h1 u-bold u-primary">Gestion des paiements</h1>
                <Card className="u-mg-top-l" title="Initialisation des tiers">
                    <DownloadLink
                        label={'Fichiers Tiers et RIB'}
                        onClick={downloadCSVTiersAndRIB}
                        isLoading={paymentsState['tiers'].loading || paymentsState['tiersRIB'].loading}
                        loadingStateKeysToWatch={['tiers', 'tiersRIB']}
                    />
                </Card>

                <div className="l-grid">
                    <div className="l-col-12 l-col-4@main">
                        <Card className="u-mg-top-l" title="Paiement des intervenants réunion de cadrage">
                            <DownloadLink
                                label={'En têtes et lignes de facture des intervenants cadrage'}
                                onClick={() => downloadCSVRefundInfosAndDetails({ refundGender: SCOPING_MEETING_PROVIDER_REFUND_ACRONYM })}
                                loadingStateKeysToWatch={[
                                    `RefundInfos-${SCOPING_MEETING_PROVIDER_REFUND_ACRONYM}`,
                                    `RefundDetails-${SCOPING_MEETING_PROVIDER_REFUND_ACRONYM}`
                                ]}
                            />
                            <section className="u-pd-vt-l">
                                <h3 className="u-blue u-fs-m u-bold u-mg-bottom-m">Historique des fichiers cadrage</h3>
                                {refundsHistory &&
                                    <RefundsHistoryTable
                                        refundGender={SCOPING_MEETING_PROVIDER_REFUND_ACRONYM}
                                        refunds={refundsHistory.filter(ref => ref.gender === SCOPING_MEETING_PROVIDER_REFUND_GENDER)}
                                    />
                                }
                            </section>
                        </Card>
                    </div>

                    <div className="l-col-12 l-col-4@main">
                        <Card className="u-mg-top-l" title="Paiement des auditeurs">
                            <DownloadLink
                                label={'En têtes et lignes de facture des auditeurs'}
                                onClick={() => downloadCSVRefundInfosAndDetails({ refundGender: AUDIT_PROVIDER_REFUND_ACRONYM })}
                                loadingStateKeysToWatch={[
                                    `RefundInfos-${AUDIT_PROVIDER_REFUND_ACRONYM}`,
                                    `RefundDetails-${AUDIT_PROVIDER_REFUND_ACRONYM}`
                                ]}
                            />
                            <section className="u-pd-vt-l">
                                <h3 className="u-blue u-fs-m u-bold u-mg-bottom-m">Historique des fichiers audit</h3>
                                {refundsHistory &&
                                    <RefundsHistoryTable
                                        refundGender={AUDIT_PROVIDER_REFUND_ACRONYM}
                                        refunds={refundsHistory.filter(ref => ref.gender === AUDIT_PROVIDER_REFUND_GENDER)}
                                    />
                                }
                            </section>
                        </Card>
                    </div>
                    <div className="l-col-12 l-col-4@main">
                        <Card className="u-mg-top-l" title="Paiement des primes des bénéficiaires">
                            <DownloadLink
                                label={'En têtes et lignes de factures des bénéficiaires'}
                                onClick={() => downloadCSVRefundInfosAndDetails({ refundGender: BENEFICIARY_REFUND_ACRONYM })}
                                loadingStateKeysToWatch={[
                                    `RefundInfos-${BENEFICIARY_REFUND_ACRONYM}`,
                                    `RefundDetails-${BENEFICIARY_REFUND_ACRONYM}`
                                ]}
                            />
                            <section className="u-pd-vt-l">
                                <h3 className="u-blue u-fs-m u-bold u-mg-bottom-m">Historique des fichiers bénéficiaires</h3>
                                {refundsHistory &&
                                    <RefundsHistoryTable
                                        refundGender={BENEFICIARY_REFUND_ACRONYM}
                                        refunds={refundsHistory.filter(ref => ref.gender === BENEFICIARY_REFUND_GENDER || ref.gender === BENEFICIARY_REGULARISATION_GENDER)}
                                    />
                                }
                            </section>
                        </Card>
                    </div>
                </div>
            </div>
        </BackOfficeLayout>
    )
}

export default Payments