import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { 
    BACK_OFFICE_OPERATIONS, 
    getRequestToEdit, 
    isValidatedPictures,
    getFeedbackToBackOfficeUser,
    VALIDATED_STATUS,
    PENDING_STATUS,
    REFUSED_STATUS,
    GO_BACK_ADVISOR_STATUS,
    advisorStatusList,
    supervisorStatusList
} from 'utils/domain/request'
import * as CatalogApi from 'services/api/CatalogApi'
import { useAlert } from 'hooks/useAlert'
import { useModal } from 'hooks/useModal'
import { useAuth } from 'hooks/useAuth'
import { privateRoutes } from 'router/routes'
import { useRequestFolder } from 'pages/back-office/Folders/RequestFolder/EquipmentFolder/context'
import Picture from 'pages/back-office/Folders/RequestFolder/EquipmentFolder/Pictures/Picture'
import FormElement from 'components/shared/FormElement'
import Card from 'components/platform/Card'
import Comment from 'components/shared/Comment'
import { scrollToTop } from 'utils/scrollTo'
import { jsonHash } from 'utils/hash'
import { isNotEmpty } from 'utils/validation'

const defaultFormFields = {
    row_hash: '',
    comment: '',
    status: null
}

const RequestFolder = () => {
    const [disabledActions, setDisabledActions] = useState(false)
    const [isChangingStatus, setIsChangingStatus] = useState(false)
    const { request, beneficiary, fetchRequest } = useRequestFolder()
    const { hideModal } = useModal()
    const { addAlert } = useAlert()
    const { isABackOfficeManagementSupervisor } = useAuth()
    const history = useHistory()

    const [ formFields, setFormFields ] = useState(defaultFormFields)
    const [showLocalErrors, setShowLocalErrors] = React.useState(false)
    const [showGlobalError, setShowGlobalError] = React.useState(false)
    const [errors, setErrors] = React.useState([])


    const isSendingFub = request.request_data?.operation_data?.sending_fub ?? false

    const [
        firstPicture,
        secondPicture,
        thirdPicture,
        fourthPicture
    ] = request?.pictures

    const isFormReady = () => {
        const formReady = !(errors.length > 0)
        if (!formReady) {
            setShowLocalErrors(true)
            setShowGlobalError(true)
            addAlert('error', 'Erreur(s) dans le formulaire.')
            scrollToTop()
            return false
        }
        return true
    }

    useEffect(() => {
        setFormFields({
            comment: request?.request_data?.operation_data?.comment,
            row_hash: request?.row_hash
        })
    }, [request])

    const commentHasChanged = request?.request_data?.operation_data?.comment && formFields?.comment && jsonHash(formFields.comment) === jsonHash(request.request_data.operation_data.comment)

    const handleChangeRequestStatus = async () => {
        if(formFields.status === VALIDATED_STATUS) {
            // On valide la demande => "validé"
            validatePictures()
        } else if(formFields.status === PENDING_STATUS) {
            // On demande des infos supplémentaires au bénéficiaire => "en attente"
            try {
                const { getError } = await CatalogApi.editBackOfficeRequest(
                    getRequestToEdit(request, BACK_OFFICE_OPERATIONS.PICTURES.ASK_MORE_INFORMATIONS, getExtraDataToSend())
                )
                if (getError()) throw getError()
                addAlert('success', 'Le dossier a bien été passé en "En attente".')
                fetchRequest()
                setIsChangingStatus(false)
            } catch(error) {
                addAlert('error', error?.toString())
            }
        } else if(formFields.status === REFUSED_STATUS) {
            // On refuse la demande => "refusé"
            declineRequest()
        } else if(formFields.status === GO_BACK_ADVISOR_STATUS) {
            // On est superviseur - admin bo gestion et on passe la request en "Retour conseiller"
            await handleRemoveSendingFub()
        } else {
            // Statut "transmis_fub" => on passe le boolean à true.
            try {
                const updatedSendingFub = {
                    'last_row_hash': formFields.row_hash,
                    'request_id': request.request_id,
                    'sending_fub': true
                }
                    
                const { getError } = await CatalogApi.editBackOfficeEntityComment(updatedSendingFub)
                if (getError()) throw getError()
                addAlert('success', 'Le dossier a bien été passé en "Transmis FUB".')
                fetchRequest()
                setIsChangingStatus(false)
            } catch(error) {
                addAlert('error', error?.toString())
            }
        }
    }

    const handleRemoveSendingFub = async () => {
        try {
            const updatedSendingFub = {
                'last_row_hash': request?.row_hash,
                'request_id': request.request_id,
                'sending_fub': false
            }
                
            const { getError: getCommentError } = await CatalogApi.editBackOfficeEntityComment(updatedSendingFub)
            if (getCommentError()) throw getCommentError()
            addAlert('success', 'Le dossier a bien été retourné au conseiller.')
            fetchRequest()
            setIsChangingStatus(false)
        } catch(error) {
            addAlert('error', error?.toString())
        }
    }

    const getExtraDataToSend = () => {
        return {
            comment: formFields.comment
        }
    }

    const validatePictures = async () => {
        try {
            if(!isFormReady()) return
            setDisabledActions(true)
            const { getError } = await CatalogApi.editBackOfficeRequest(
                getRequestToEdit(request, BACK_OFFICE_OPERATIONS.PICTURES.VALIDATE, getExtraDataToSend())
            )
            if (getError()) throw getError()
            addAlert('success', 'La conformité des travaux a bien été validée.')
            history.push(privateRoutes.backOffice.backOfficeHome.path)
        } catch(error) {
            addAlert('error', error?.toString())
        } finally {
            setDisabledActions(false)
            hideModal()
        }
    }

    const askMoreInformations = async (event) => {
        try {
            setDisabledActions(true)
            const subject = `OEPV: Demande d'infos complémentaires pour le dossier n°${request?.request_fub_id}`
            window.location = `mailto:${beneficiary?.contactEmail}?subject=${subject}`
            event.preventDefault()
        } catch(error) {
            addAlert('error', error?.toString())
        } finally {
            setDisabledActions(false)
        }
    }

    const saveComment = async () => {
        try {
            setDisabledActions(true)

            const updatedComment = {
                'last_row_hash': request.row_hash,
                'request_id': request.request_id,
                'comment': formFields.comment
            }

            const { getError } = await CatalogApi.editBackOfficeEntityComment(updatedComment)
            if (getError()) throw getError()

            addAlert('success', 'Le commentaire a bien été ajouté')
            fetchRequest()
        } catch(error) {
            addAlert('error', error?.toString())
        } finally {
            setDisabledActions(false)
        }
    }

    const declineRequest = async () => {
        try {
            if(!isFormReady()) return
            setDisabledActions(true)
            const { getError } = await CatalogApi.editBackOfficeRequest(
                getRequestToEdit(request, BACK_OFFICE_OPERATIONS.PICTURES.DECLINE, getExtraDataToSend())
            )
            if (getError()) throw getError()

            addAlert('success', 'Le dossier a bien été refusé')
            history.push(privateRoutes.backOffice.backOfficeHome.path)
        } catch(error) {
            addAlert('error', error?.toString())
        } finally {
            setDisabledActions(false)
            hideModal()
        }
    }

    const handleInputChange = (event) => {
        const value = event.target.value
        const name = event.target.name

        setFormFields({
            ...formFields,
            [name]: value
        })
    }

    const handleValidation = React.useCallback((name, errs) => {
        setErrors((s) => {
            const cleanErrors = [...s.filter((e) => e.origin !== name)]
            return [
                ...cleanErrors,
                ...errs
            ]
        })
      }, [])

    return (
        <form className={`${isSendingFub && !isABackOfficeManagementSupervisor ? 'is-disabled-block' : ''}`}>
            { showGlobalError && <p className="u-fs-xs u-center u-danger u-mg-bottom-m">Il y a eu une erreur qui empêche la validation du formulaire.</p>}
            <Card 
                className="u-pd-l" 
                title="Validation de la conformité des travaux" 
                status={getFeedbackToBackOfficeUser(request?.status)}
            >
                <h4 className="c-h4 u-bold u-primary">Devis n°{request?.request_fub_id}</h4>
                <div className="u-flex u-flex-dir-row u-mg-top-m">
                    <Picture 
                        picture={firstPicture} 
                        label="Vue d’ensemble de l’installation dans son environnement" 
                        alt="'Vue d’ensemble de l’installation dans son environnement"
                    />
                    <Picture 
                        picture={secondPicture} 
                        label="Vue d’ensemble de l’installation avec les supports d’attache visibles" 
                        alt="Vue d’ensemble de l’installation avec les supports d’attache visibles"
                    />
                    <Picture 
                        picture={thirdPicture} 
                        label="Zoom sur un support d’attache" 
                        alt="Zoom sur un support d’attache"
                    />
                    <Picture 
                        picture={fourthPicture} 
                        label="Photo complémentaire" 
                        alt="Photo complémentaire"
                    />
                </div>
                <Comment
                    value={formFields.comment}
                    showLocalErrors={showLocalErrors}
                    handleValidation={handleValidation}
                    handleInputChange={handleInputChange}
                    placeholder={'Ajouter un commentaire à votre dossier'}
                    maxLength={1000}
                />
                <button
                    type="button"
                    className="c-btn c-btn--s c-btn--primary c-btn--back-office u-pd-hz-m u-pd-s"
                    onClick={saveComment}
                    disabled={commentHasChanged || disabledActions}
                >
                    Enregistrer le commentaire
                </button>


                {!isValidatedPictures(request?.status) && (
                    <>
                                            {isChangingStatus &&
                            <div className="u-flex u-flex-center-hz u-flex-end-vt u-mg-top-m">
                                <button
                                    type="button"
                                    className="c-btn c-btn--s c-btn--danger c-btn--back-office u-pd-hz-m u-mg-hz-m u-pd-s"
                                    onClick={() => {
                                        setIsChangingStatus(false)
                                    }}
                                >
                                    Annuler
                                </button>

                                {/* List of advisors */}
                                <FormElement
                                    value={formFields.status}
                                    options={isABackOfficeManagementSupervisor ? supervisorStatusList(request) : advisorStatusList(request)}
                                    name="status"
                                    type="select"
                                    label="Changer le statut"
                                    className="u-pd-hz-m"
                                    showErrors={showLocalErrors}
                                    onValidate={handleValidation}
                                    onChange={handleInputChange}
                                    isOptionDisabled={(option) => option.disabled}
                                />
                                
                                <button
                                    type="button"
                                    className="c-btn c-btn--s c-btn--primary c-btn--back-office u-pd-hz-m u-mg-hz-m u-pd-s"
                                    onClick={handleChangeRequestStatus}
                                    disabled={
                                        !isNotEmpty(formFields.status)
                                    }
                                >
                                    Valider
                                </button>
                            </div>
                        }
                        {!isChangingStatus &&
                            <div className="u-flex u-flex-dir-row u-flex-center-hz u-mg-top-m">
                                <button
                                    type="button"
                                    className="c-btn c-btn--s c-btn--primary c-btn--back-office u-pd-hz-m u-mg-m u-pd-s"
                                    onClick={() => setIsChangingStatus(true)}
                                    disabled={disabledActions}
                                >
                                    Changer le statut
                                </button>
                                <button
                                    type="button"
                                    className="c-btn c-btn--s c-btn--primary c-btn--back-office u-pd-hz-m u-mg-m u-pd-s"
                                    onClick={askMoreInformations}
                                    disabled={disabledActions}
                                >
                                    Demander des informations complémentaires
                                </button>
                            </div>
                            }</>
                )}
            </Card>
        </form>
    )
}

export default RequestFolder