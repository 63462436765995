import React, { useEffect } from 'react'
import { string } from 'prop-types'
import { NavLink, useHistory } from 'react-router-dom'
import { privateRoutes } from 'router/routes'

import dateService from 'services/date'
import * as CatalogApi from 'services/api/CatalogApi'

import { useAlert } from 'hooks/useAlert'
import { useModal } from 'hooks/useModal'
import useBonus from 'hooks/useBonus'

import { getBeneficiary } from 'utils/domain/admin'
import { FOLDER_PAID, REQUEST_VALIDATED } from 'utils/domain/request'
import { groupRequestsByGender } from 'utils/domain/request'
import { scrollToTop } from 'utils/scrollTo'

import Card from 'components/platform/Card'
import FormElement from 'components/shared/FormElement'
import BeneficiaryInformations from 'components/back-office/BeneficiaryInformations'


// import './styles.scss'
import { formatNumberAndAddEurCurrency } from 'utils/number'
import ModalConfirmation from 'components/shared/ModalConfirmation'

const defaultFormFields = {
    targetedRequest: '',
    amountToRefund: '',
    comment: ''
}

const defaultFormEvents = {
    ready: true,
}

const BeneficiaryRegularisation = ({ beneficiaryId }) => {
    const [formFields, setFormFields] = React.useState(defaultFormFields)
    const [formEvents, setFormEvents] = React.useState(defaultFormEvents)

    const [errors, setErrors] = React.useState([])
    const [showLocalErrors, setShowLocalErrors] = React.useState(false)
    const [showGlobalError, setShowGlobalError] = React.useState(false)

    const { getRequestsWithBonus } = useBonus({ isFromBackOffice: true })
    const [beneficiary, setBeneficiary ] = React.useState()

    const currentRegularisationId = Date.now()

    const { addAlert } = useAlert()
    const { showModal } = useModal()
    const history = useHistory()

    const requestsGroupedByGender = beneficiary?.requestsGroupedByGender || {}
    const requests = Object.values(requestsGroupedByGender).flat()
    const paidOrValidatedRequestsOptions = requests
        .filter(({ status }) => (status === REQUEST_VALIDATED) || (status === FOLDER_PAID))
        .map(request => ({
            label: `${request?.genderLabel} par "${request?.provider_data?.structureName}" - ${formatNumberAndAddEurCurrency(request?.realBonus ?? request?.bonus)} le ${dateService.formatDate(request?.create_date)} (n°${request?.request_fub_id})`,
            value: request?.request_id
        }))

    useEffect(() => {
        loadBeneficiaryDetails()
    }, [beneficiaryId]) // eslint-disable-line react-hooks/exhaustive-deps

    const loadBeneficiaryDetails = async () => {
        try {
            const beneficiaryResponse = await getBeneficiary(beneficiaryId)
            const { res: requests, getError } = await CatalogApi.getBackOfficeRequestsByEntity(beneficiaryId)
            if (getError()) throw getError()
            const requestsWithBonus = await getRequestsWithBonus(requests, beneficiaryResponse?.entity_data?.structureEmployeeQuantity)

            setBeneficiary({
                ...beneficiaryResponse,
                requestsGroupedByGender: groupRequestsByGender(requestsWithBonus),
            })
        } catch(error) {
            console.error(error)
            addAlert('error', error?.toString())
        }
    }

    const handleInputChange = (event) => {
        const target = event.target
        const value = target.type === 'checkbox'
            ? target.checked
            : target.type === 'file'
                ? target
                : target.value
        const name = target.name

        setFormFields({
            ...formFields,
            [name]: value
        })
    }

    const handleValidation = React.useCallback((name, errs) => {
        setErrors((s) => {
            const cleanErrors = [...s.filter((e) => e.origin !== name)]
            return [
                ...cleanErrors,
                ...errs
            ]
        })
    }, [])

    const handleSubmitValidation = async () => {
        // Validate inputs
        const formReady = !(errors.length > 0)
        if (!formReady) {
            setShowLocalErrors(true)
            setShowGlobalError(true)
            addAlert('error', 'Erreur(s) dans le formulaire.')
            scrollToTop()
            return
        }
        try {
            setShowGlobalError(false)
            setShowLocalErrors(false)
            setFormEvents({ ready: false })

            // call API to update beneficiary
            const regularisationFolderCreationPayload = {
                entity_id: beneficiary?.entity_id,
                request_id: formFields?.targetedRequest,
                amount: +formFields?.amountToRefund,
                comment: formFields?.comment,
                unique_id: currentRegularisationId
            }

            const { getError } = await CatalogApi.createRegularisationFolder(regularisationFolderCreationPayload)
            if (getError()) throw getError()

            addAlert('success', 'Le dossier de régularisation a bien été créé')

            // Reset form
            setFormFields(defaultFormFields)
            setFormEvents({ ready: true })
            // Redirect
            history.push(privateRoutes.backOffice.beneficiaryDetails.navLink({ beneficiaryId }))

        } catch (error) {
            addAlert('error', error?.toString())
            setShowGlobalError(true)
            setFormEvents({ ready: true })
            scrollToTop()
        }
    }

    const showValidationModal = (event) => {
        event.preventDefault()
        showModal(
            ModalConfirmation,
            {
                title: 'Êtes-vous sûr(e) de vouloir créer ce dossier ?',
                body: 'En cliquant sur "confirmer" vous ouvrez une nouvelle demande de régularisation.',
                validate: handleSubmitValidation,
                closeAfterValidation: true
            }
        )
    }

    return (
        <>
        { !beneficiary &&
            <div className="u-center u-pd-vt-xxl">
                <div className="c-spinner"></div>
            </div>
        }
        { beneficiary &&
            <form>
                <div>
                    <h1 className="c-h1 u-inline-block u-mg-right-xl u-mg-bottom-l u-bold u-primary">
                        Dossier de régularisation : {beneficiary?.entity_data?.structureName}
                    </h1>

                    <div className="l-grid u-mg-left-s u-mg-right-s u-flex-nowrap">

                        <div className="l-col-8">
                            <Card title="" className="">
                                <form>

                                    {showGlobalError &&
                                        <p className="u-fs-xs u-danger u-center u-mg-bottom-m">Un problème est survenu lors de la création du dossier de régularisation.</p>
                                    }

                                    <div className="l-grid">
                                        <div className="l-col-12 l-col-6@main u-pd-m">
                                            <FormElement
                                                value={formFields.targetedRequest}
                                                options={paidOrValidatedRequestsOptions}
                                                name="targetedRequest"
                                                type="select"
                                                label="Dossier concerné"
                                                className="u-mg-bottom-l"
                                                required={true}
                                                showErrors={showLocalErrors}
                                                onValidate={handleValidation}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="l-grid">
                                        <div className="l-col-12 l-col-6@main u-pd-m">
                                            <FormElement
                                                value={formFields.amountToRefund}
                                                name="amountToRefund"
                                                type="number"
                                                label="Montant de la régularisation en € HT"
                                                className="u-mg-bottom-l"
                                                maxLength={5}
                                                required={true}
                                                showErrors={showLocalErrors}
                                                onValidate={handleValidation}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="l-grid">
                                        <div className="l-col-12 u-pd-m">
                                            <FormElement
                                                value={formFields.comment}
                                                name="comment"
                                                type="textarea"
                                                label="Commentaire"
                                                placeholder="Ajouter un commentaire à votre dossier"
                                                className="u-mg-hz-auto u-mg-bottom-l"
                                                required={false}
                                                showErrors={showLocalErrors}
                                                onValidate={handleValidation}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="u-flex u-flex-center-hz u-mg-top-l u-mg-bottom-m">
                                        {formEvents.ready &&
                                            <>
                                                <NavLink
                                                    to={privateRoutes.backOffice.beneficiaryDetails.navLink({ beneficiaryId })}
                                                    className="c-btn c-btn--white-bordered u-pd-hz-xl u-mg-hz-m"
                                                >
                                                    Annuler
                                                </NavLink>
                                                <button
                                                    type="submit"
                                                    className="c-btn c-btn--primary u-pd-hz-xl u-mg-hz-m"
                                                    onClick={showValidationModal}
                                                >
                                                    Valider le dossier
                                                </button>
                                            </>
                                        }
                                        {!formEvents.ready &&
                                            <div className="c-spinner"></div>
                                        }
                                    </div>
                                </form>
                            </Card>
                        </div>

                        <BeneficiaryInformations
                            initialBeneficiary={beneficiary}
                            beneficiaryId={beneficiaryId}
                            className={'l-col-4'}
                        />

                    </div>
                </div>
            </form>
            }
        </>
    )
}

export default BeneficiaryRegularisation

BeneficiaryRegularisation.propTypes = {
    beneficiaryId: string,
}